"use client";

import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/shared/utils";

const labelVariants = cva("leading-none text-content-secondary peer-disabled:cursor-not-allowed peer-disabled:opacity-70", {
    variants: {
        variant: {
            floating: "label--floating mb-0 bg-background-primary text-base",
            default: "label--default bg-transparent text-sm font-medium leading-5 text-content-primary"
        },
        size: {
            xs: "text-xs",
            sm: "text-sm",
            base: "text-base"
        },
        bgColor: {
            default: "bg-background-primary",
            transparent: "bg-background-transparent"
        }
    },
    defaultVariants: {
        variant: "default",
        bgColor: "transparent"
    }
});

interface LabelProps extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>, VariantProps<typeof labelVariants> {
    allowLabelClick?: boolean;
}

const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, LabelProps>(({ className, variant, size, bgColor, allowLabelClick = false, ...props }, ref) => (
    <LabelPrimitive.Root ref={ref} className={cn(labelVariants({ size, variant, bgColor }), !allowLabelClick && "pointer-events-none", className)} {...props} />
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label, labelVariants };
