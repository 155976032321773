// builderTestStore.ts
import { create } from "zustand";

interface BuilderTestState {
    builderTestIds: string[];
    builderVariationIds: string[];
    addTestData: (testId: string, variationId: string) => void;
    resetTestData: () => void;
}

const builderTestStore = create<BuilderTestState>(set => ({
    builderTestIds: [],
    builderVariationIds: [],
    addTestData: (testId: string, variationId: string) =>
        set(state => ({
            builderTestIds: Array.from(new Set([...state.builderTestIds, testId])),
            builderVariationIds: Array.from(new Set([...state.builderVariationIds, variationId]))
        })),
    resetTestData: () =>
        set({
            builderTestIds: [],
            builderVariationIds: []
        })
}));

export const useBuilderTestStore = builderTestStore;

export const useBuilderTestIds = () => builderTestStore(state => state.builderTestIds);
export const useBuilderVariationIds = () => builderTestStore(state => state.builderVariationIds);
export const useBuilderTestActions = () =>
    builderTestStore(state => ({
        addTestData: state.addTestData,
        resetTestData: state.resetTestData
    }));
