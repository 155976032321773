// utils/CookieUtils.ts
import Cookies from "js-cookie";
import { PublicConfig } from "../PublicConfig";

export class CookieUtils {
    /**
     * Determines the appropriate cookie domain based on the current environment
     * @private
     * @returns {string} Cookie domain (e.g., "localhost" or ".xyz.com")
     */
    private static getCookieDomain(): string {
        if (PublicConfig.IS_LOCAL_ENV) {
            return "localhost";
        }

        if (typeof window === "undefined") {
            throw new Error("getCookieDomain must be called in browser context");
        }

        const hostname = window.location.hostname;
        const parts = hostname.split(".");

        if (parts.length < 2) {
            throw new Error(`Invalid hostname: ${hostname}`);
        }

        if (hostname.includes("spotpet")) {
            const rootDomain = parts.slice(-2).join(".");
            return `.${rootDomain}`;
        } else {
            return hostname;
        }
    }

    /**
     * Gets the default cookie options with the appropriate domain
     * @private
     * @returns {Cookies.CookieAttributes} Default cookie options
     */
    private static getDefaultOptions(additionalOptions?: Cookies.CookieAttributes): Cookies.CookieAttributes {
        return {
            path: "/",
            domain: this.getCookieDomain(),
            sameSite: "lax",
            ...additionalOptions
        };
    }

    /**
     * Sets a cookie with cross-domain support
     * @param name Cookie name
     * @param value Cookie value
     * @param options Additional cookie options
     */
    public static set(name: string, value: string, options?: Cookies.CookieAttributes): void {
        Cookies.set(name, value, this.getDefaultOptions(options));
    }

    /**
     * Gets a cookie value
     * @param name Cookie name
     * @returns {string | undefined} Cookie value if exists
     */
    public static get(name: string): string | undefined {
        return Cookies.get(name);
    }

    /**
     * Removes a cookie
     * @param name Cookie name
     * @param options Additional cookie options
     */
    public static remove(name: string, options?: Cookies.CookieAttributes): void {
        Cookies.remove(name, this.getDefaultOptions(options));
    }

    /**
     * Gets all cookies as an object
     * @returns {Object} Object containing all cookies
     */
    public static getAll(): { [key: string]: string } {
        return Cookies.get();
    }

    /**
     * Checks if a cookie exists
     * @param name Cookie name
     * @returns {boolean} True if cookie exists
     */
    public static exists(name: string): boolean {
        return !!this.get(name);
    }

    /**
     * Sets a cookie with JSON value
     * @param name Cookie name
     * @param value Object to store as JSON
     * @param options Additional cookie options
     */
    public static setJSON<T>(name: string, value: T, options?: Cookies.CookieAttributes): void {
        this.set(name, JSON.stringify(value), options);
    }

    /**
     * Gets a cookie value as parsed JSON
     * @param name Cookie name
     * @returns {T | null} Parsed JSON value or null if invalid/not found
     */
    public static getJSON<T>(name: string): T | null {
        const value = this.get(name);
        if (!value) return null;

        try {
            return JSON.parse(value) as T;
        } catch {
            return null;
        }
    }
}
