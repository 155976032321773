import React, { useRef, useState } from "react";
import debounce from "lodash/debounce";
import { useIntersectionObserver } from "@/shared/hooks/useIntersectionObserver";
import { LazyMotion, domAnimation, m, AnimatePresence } from "framer-motion";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MultiPetSelectProps } from "./types";
import { Button } from "@/shared/components/ui/Button";
import { Carousel, CarouselApi, CarouselContent, CarouselItem, CarouselNavDots } from "@/shared/components/ui/carousel";
import PetCard, { PlaceholderPetCard } from "./PetCard";
import PetModal from "../PetModal";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { StickyMenu } from "./StickyMenu";
import { cn } from "@/shared/utils";
import { Policy } from "@/shared/types/Quote.interface";
import { useCoverageEditorContext } from "../CoverageEditor";
import Strings from "@/shared/utils/Strings.constants";

const MultiPetSelectCard: React.FC<Omit<MultiPetSelectProps, "variant">> = ({
    activePet,
    policyPets,
    handleActivePetChange,
    isQuoteUpdating,
    policies,
    petModalConfig,
    isMultiPetLinked,
    isApplyAllHidden
}) => {
    const [carouselApi, setCarouselApi] = useState<CarouselApi | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const editorConfig = useCoverageEditorContext();
    const { getModalInitialValues } = editorConfig;
    const breakpoint = useAppLayerContext().appState.breakpoint;
    const quoteId = useAppLayerContext().appState.quoteQuery?.data?.id;
    const defaultCoverageSettings = policies[0]?.coverageSettings;
    const { ref: addAPetRef, isIntersecting } = useIntersectionObserver();
    const isAddAPetInView = isIntersecting ?? true;

    const handleAddPet = () => {
        setIsModalOpen(true);
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        handleAddPet();
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            handleAddPet();
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handlePetSelection = (selectedIndex: number) => {
        const petIdToSet = policyPets?.[selectedIndex]?.value;
        if (petIdToSet) {
            handleActivePetChange(petIdToSet);
        }
    };

    const renderItems = () => {
        if (isApplyAllHidden || !isMultiPetLinked || (isMultiPetLinked && policies.length === 1)) {
            return policies.map((pet, index) => (
                <CarouselItem key={pet.id} className="h-full pl-0" aria-selected={activePet.id === pet.id} data-testid={`pet-card-slide-${index}`}>
                    <PetCard
                        pet={pet}
                        isQuoteUpdating={isQuoteUpdating}
                        petModalConfig={petModalConfig}
                        activePetId={activePet.id ?? ""}
                        quoteId={quoteId ?? ""}
                        policiesCount={policies.length}
                        handleActivePetChange={handleActivePetChange}
                        isMultiPetLinked={isMultiPetLinked}
                        isApplyAllHidden={isApplyAllHidden}
                        onSelect={() => carouselApi?.scrollTo(index)}
                    />
                </CarouselItem>
            ));
        }
        return (
            <CarouselItem className="h-full pl-0">
                <PetCard
                    pet={activePet}
                    isQuoteUpdating={isQuoteUpdating}
                    petModalConfig={petModalConfig}
                    activePetId={activePet.id ?? ""}
                    quoteId={quoteId ?? ""}
                    policiesCount={policies.length}
                    handleActivePetChange={handleActivePetChange}
                    isMultiPetLinked={isMultiPetLinked}
                    isApplyAllHidden={isApplyAllHidden}
                />
            </CarouselItem>
        );
    };

    const activePetIndex = policies.findIndex(pet => !!pet.id && pet.id === activePet.id);

    const containerRef = useRef(null);

    const MAX_VISIBLE_CARDS = 6;

    const renderStackedCards = () => {
        const totalCards = policies.length;
        const leftCount = Math.min(Math.floor(MAX_VISIBLE_CARDS / 2), activePetIndex);
        const rightCount = Math.min(Math.floor(MAX_VISIBLE_CARDS / 2), totalCards - activePetIndex - 1);

        const leftCards = policies.slice(Math.max(0, activePetIndex - leftCount), activePetIndex).reverse();
        const rightCards = policies.slice(activePetIndex + 1, activePetIndex + 1 + rightCount);
        const policyToShow = policies[activePetIndex];

        return (
            <>
                {leftCards.map((pet, index) => renderCard(pet, -(leftCards.length - index)))}
                {!!policyToShow && renderCard(policyToShow, 0)}
                {rightCards.map((pet, index) => renderCard(pet, index + 1))}
            </>
        );
    };

    const renderCard = (pet: Policy, offset: number) => {
        const absOffset = Math.abs(offset);
        const bgColor = absOffset % 2 === 1 ? "bg-background-tertiary" : "bg-background-secondary";

        return (
            <m.div
                key={pet.id}
                style={{
                    position: "absolute",
                    width: "328px",
                    height: "100%",
                    zIndex: MAX_VISIBLE_CARDS - absOffset
                }}
                initial={{
                    x: offset * 328,
                    y: 0,
                    scale: 1,
                    opacity: 1
                }}
                animate={{
                    x: offset * 1,
                    y: absOffset * 7,
                    scale: 1 - absOffset * 0.04,
                    opacity: 1
                }}
                transition={{
                    duration: 0.3,
                    delay: absOffset * 0.1,
                    type: "spring",
                    stiffness: 120,
                    damping: 20
                }}
            >
                {offset === 0 ? (
                    <PetCard
                        pet={{ ...pet, name: `${policies.length} Pets` }}
                        isQuoteUpdating={isQuoteUpdating}
                        petModalConfig={petModalConfig}
                        activePetId={pet.id ?? ""}
                        quoteId={quoteId ?? ""}
                        policiesCount={policies.length}
                        handleActivePetChange={handleActivePetChange}
                        isMultiPetLinked={isMultiPetLinked}
                    />
                ) : (
                    <PlaceholderPetCard pet={pet} bgColor={bgColor} />
                )}
            </m.div>
        );
    };

    if (policies.length === 0) return null;

    const showCarouselDots = (policies.length < 9 && breakpoint === "xs") || (policies.length < 16 && breakpoint !== "xs");
    return (
        <>
            <StickyMenu isVisible={!isAddAPetInView} />
            <LazyMotion features={domAnimation}>
                <div className="flex h-full flex-col sm:items-center">
                    <div ref={containerRef} className="flex w-full justify-center contain-layout sm:w-screen">
                        <AnimatePresence mode="popLayout" initial={false}>
                            {isMultiPetLinked && !isApplyAllHidden ? (
                                <m.div key="linked" className="relative h-[177px] w-[328px]" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                                    {renderStackedCards()}
                                </m.div>
                            ) : (
                                <m.div key="unlinked" initial={false} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                                    <Carousel
                                        options={{ startIndex: !isMultiPetLinked ? activePetIndex : 0 }}
                                        className="grid auto-cols-[328px] grid-flow-col"
                                        setApi={api => {
                                            if (!api) return;
                                            setCarouselApi(api);
                                            const debouncedHandlePetSelection = debounce((selectedIndex: number) => {
                                                handlePetSelection(selectedIndex);
                                            }, 300);

                                            api.on("select", () => {
                                                const selectedIndex = api.selectedScrollSnap() ?? 0;
                                                debouncedHandlePetSelection(selectedIndex);
                                            });
                                        }}
                                        disabled={policies.length === 1 || isMultiPetLinked}
                                    >
                                        <div className="flex flex-col items-center">
                                            <CarouselContent className="flex h-full w-[328px] gap-1" contentWrapperClass="overflow-visible">
                                                {renderItems()}
                                            </CarouselContent>
                                            {showCarouselDots && <CarouselNavDots className="mt-4" />}
                                        </div>
                                    </Carousel>
                                </m.div>
                            )}
                        </AnimatePresence>
                    </div>
                    <div className={cn("mt-4 flex justify-center")}>
                        <PetModal
                            initialValues={{ policies: [getModalInitialValues(defaultCoverageSettings)] }}
                            triggerButton={
                                <Button
                                    ref={addAPetRef}
                                    loading={isQuoteUpdating}
                                    loadingSpinnerPosition="start"
                                    onClick={handleAddPet}
                                    onTouchStart={handleTouchStart}
                                    onKeyDown={handleKeyDown}
                                    variant="outline-secondary"
                                    size="sm"
                                    className="text-xs"
                                    startDecorator={<FontAwesomeIcon icon={faPlus} className="size-4" />}
                                >
                                    {Strings.ADD_PET_GET_DISCOUNT}
                                </Button>
                            }
                            isOpen={isModalOpen}
                            handleOpen={() => setIsModalOpen(true)}
                            onClose={handleCloseModal}
                            config={petModalConfig}
                            handleActivePetChange={handleActivePetChange}
                        />
                    </div>
                </div>
            </LazyMotion>
        </>
    );
};

export default MultiPetSelectCard;
