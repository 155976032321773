import { useEffect, useRef } from "react";
import { useStatsigClient, useStatsigUser } from "@statsig/react-bindings";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";

type AllowedDefaultValue = string | number | boolean | { [key: string]: AllowedDefaultValue } | AllowedDefaultValue[];

type LayerParameter<K extends string = string, V extends AllowedDefaultValue = AllowedDefaultValue> = {
    key: K;
    defaultValue: V;
};

type Widen<T> = T extends string
    ? string
    : T extends number
      ? number
      : T extends boolean
        ? boolean
        : T extends Array<infer U>
          ? Array<Widen<U>>
          : T extends { [K in keyof T]: T[K] }
            ? { [K in keyof T]: Widen<T[K]> }
            : T;

type Mutable<T> = T extends object ? { -readonly [P in keyof T]: Mutable<T[P]> } : T;

type InferLayerValues<T extends readonly LayerParameter[]> = {
    [P in T[number] as P["key"]]: Mutable<Widen<P["defaultValue"]>>;
};

interface UseStatsigOptions<T extends readonly LayerParameter[]> {
    layerId: string;
    layerParameters: T;
    queryParams?: Record<string, string>;
}

export function useStatsig<T extends readonly LayerParameter<any>[]>({ layerId, layerParameters, queryParams }: UseStatsigOptions<T>): InferLayerValues<T> {
    const statsigClient = useStatsigClient();
    const { getLayer } = statsigClient;
    const user = useStatsigUser();
    const exposureLoggedRef = useRef(false);

    const testLayer = getLayer(layerId);

    const layerValues = layerParameters.reduce((acc, param) => {
        const value = testLayer.get(param.key, param.defaultValue) as Widen<typeof param.defaultValue>;
        return {
            ...acc,
            [param.key]: value
        };
    }, {} as InferLayerValues<T>);

    useEffect(() => {
        const shouldLogExposure =
            !exposureLoggedRef.current &&
            !!testLayer &&
            !!user.user.userID &&
            !!testLayer.__evaluation?.allocated_experiment_name &&
            areKeysMatching(
                testLayer.__evaluation.explicit_parameters,
                layerParameters.map(param => param.key)
            );

        if (shouldLogExposure) {
            exposureLoggedRef.current = true;
            AnalyticsUtils.sendDataDogAction("statsig_exposure", {
                extra: {
                    quote: {
                        extra: {
                            queryParams,
                            statsig: {
                                userID: user.user.userID,
                                [testLayer.name]: {
                                    ruleID: testLayer.__evaluation?.rule_id,
                                    allocatedExperimentName: testLayer.__evaluation?.allocated_experiment_name
                                }
                            }
                        }
                    }
                }
            });
        }
    }, [testLayer, user.user.userID, layerParameters, queryParams]);

    return layerValues;
}

function areKeysMatching(explicitParameters: string[] | undefined, keysToMatch: string[]): boolean {
    if (!explicitParameters) return false;
    return keysToMatch.every(key => explicitParameters.includes(key));
}
