import React, { useMemo } from "react";
import { Card } from "@/shared/components/ui/Card";
import { Heading } from "@/shared/components/ui/Heading";
import IconGrid from "@/shared/components/IconGrid";
import { useModal } from "@/shared/hooks/useModal";
import { IconUtils } from "@/shared/utils/IconUtils";
import { CoverageSettings } from "@/shared/types/Quote.interface";
import { Species } from "spot-types/entities/Species";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { DialogProps } from "@/shared/types/Modals";
import { useMediaQuery } from "@react-hookz/web";
import { IconGridProps } from "../types/IconGrid.interface";
import { cn } from "../utils";
import FullCoverageDetailsButton from "./FullCoverageDetailsButton";

interface CoverageIconGridProps {
    title?: string;
    coverageInfo: CoverageSettings;
    species?: Species;
    underwriter: PetUnderwriterType;
    samplePolicyUrl: string;
    modalContent?: React.ComponentType<DialogProps>;
    wrapperClass?: string;
    titleModal?: boolean;
    overrideScreenCheck?: boolean;
    iconsPerBreakpoint?: { XS: number; SM: number; MD: number; LG: number; XL: number };
    iconGridProps: Pick<IconGridProps, "itemClass" | "overrideScreenCheck" | "wrapperClass">;
    iconHeaderVariant?: string;
    renderCoverageTypes?: () => React.ReactNode;
    renderCoverageFields?: () => React.ReactNode;
    renderReimbursementTerms?: () => React.ReactNode;
}

export function CoverageIconGrid(props: CoverageIconGridProps) {
    const { title, titleModal, coverageInfo, species, underwriter, samplePolicyUrl, modalContent, wrapperClass, iconsPerBreakpoint, iconGridProps } = props;

    // Todo: move this to new useModal hook via ModalProvider context
    // this will require updating FullCoverageDetailsModal component
    const modal = useModal();

    const coverageTypes = coverageInfo.coverages?.map(coverage => coverage.type);
    const hasAccidentAndIllness = coverageTypes?.includes("accident") && coverageTypes?.includes("illness");
    const hasAccidentOnly = coverageTypes?.includes("accident") && !coverageTypes?.includes("illness");

    const getCoverageTitle = () => {
        if (props.iconHeaderVariant === "row") return "Coverage";
        if (title) return title;
        if (props.iconHeaderVariant === "centered" || hasAccidentOnly || hasAccidentAndIllness) {
            return "Helps cover eligible claims for:";
        }
        return "";
    };

    // TODO: refactor to use appState.breakpoint = "MD"
    const isXLBreakpoint = useMediaQuery("only screen and (min-width: 1280px)");
    const isLargeBreakpoint = useMediaQuery("only screen and (min-width: 1024px)");
    const isMediumBreakpoint = useMediaQuery("only screen and (min-width: 768px) and (max-width: 1023px)");
    const isSmallBreakpoint = useMediaQuery("only screen and (min-width: 640px) and (max-width: 767px)");
    const isXSBreakpoint = useMediaQuery("only screen and (max-width: 639px)");

    const initialViewableCount = useMemo(() => {
        const iconsPerBreakpointDefault = {
            XS: props.iconHeaderVariant === "row" ? 9 : 8,
            SM: 9,
            MD: 12,
            LG: 20,
            XL: 20
        };

        if (isXLBreakpoint) return iconsPerBreakpoint?.XL || iconsPerBreakpointDefault.XL;
        if (isLargeBreakpoint) return iconsPerBreakpoint?.LG || iconsPerBreakpointDefault.LG;
        if (isMediumBreakpoint) return iconsPerBreakpoint?.MD || iconsPerBreakpointDefault.MD;
        if (isSmallBreakpoint) return iconsPerBreakpoint?.SM || iconsPerBreakpointDefault.SM;
        if (isXSBreakpoint) return iconsPerBreakpoint?.XS || iconsPerBreakpointDefault.XS;
    }, [isXSBreakpoint, isSmallBreakpoint, isMediumBreakpoint, isLargeBreakpoint, isXLBreakpoint, iconsPerBreakpoint]);

    const coverageIcons = useMemo(() => {
        const iconSize = props.iconHeaderVariant === "row" && !isLargeBreakpoint ? "size-12" : "size-16";
        if (hasAccidentOnly && species) return IconUtils.getAccidentIcons(species, underwriter, iconSize);
        if (hasAccidentAndIllness && species) return IconUtils.getAccidentIllnessIcons(species, underwriter, iconSize);
        return [];
    }, [species, hasAccidentOnly, hasAccidentAndIllness, underwriter, props.iconHeaderVariant]);

    const renderCoverageDetailsButton = () => (
        <div
            className={cn(
                "flex w-full justify-center whitespace-nowrap",
                props.iconHeaderVariant === "centered" ? "justify-center" : "",
                props.iconHeaderVariant === "row" ? "justify-end" : "",
                titleModal ? "lg:hidden" : ""
            )}
        >
            <FullCoverageDetailsButton modal={modal} modalContent={modalContent as React.FC<DialogProps>} samplePolicyUrl={samplePolicyUrl} />
        </div>
    );

    return (
        <Card
            className={cn(
                "text-type-primary flex flex-col items-center gap-4 rounded-3xl bg-background-primary px-4",
                props.iconHeaderVariant === "row" ? "py-3" : "py-6",
                wrapperClass
            )}
        >
            <div
                className={cn(
                    "flex w-full items-center",
                    props.iconHeaderVariant === "centered" ? "flex-col gap-1 lg:w-full lg:flex-row" : "flex-row justify-between",
                    titleModal ? "lg:justify-between" : ""
                )}
            >
                <Heading
                    level="h3"
                    styleAs="h4"
                    className={cn(
                        "w-full font-bold",
                        props.iconHeaderVariant === "row" || props.iconHeaderVariant === "centered" ? "text-base" : "text-sm",
                        props.iconHeaderVariant === "row" ? "" : "text-center",
                        titleModal ? "lg:w-auto lg:text-left" : ""
                    )}
                >
                    {getCoverageTitle()}
                </Heading>
                {(props.iconHeaderVariant === "centered" || props.iconHeaderVariant === "row") && renderCoverageDetailsButton()}
                {titleModal && (
                    <FullCoverageDetailsButton
                        modal={modal}
                        modalContent={modalContent as React.FC<DialogProps>}
                        samplePolicyUrl={samplePolicyUrl}
                        buttonClass="hidden lg:inline-flex"
                    />
                )}
            </div>
            {props.iconHeaderVariant === "row" && <div className="w-full lg:hidden">{props.renderCoverageTypes?.()}</div>}

            <IconGrid
                gridItems={coverageIcons}
                wrapperClass={iconGridProps.wrapperClass}
                initialViewableCount={initialViewableCount}
                isLargeScreen={isLargeBreakpoint}
                overrideScreenCheck={iconGridProps.overrideScreenCheck}
                itemClass={iconGridProps.itemClass}
                iconHeaderVariant={props.iconHeaderVariant}
            />
            {props.iconHeaderVariant === "row" && (
                <div className="-mx-4 w-[calc(100%+32px)] border-t border-stroke-primary lg:hidden">
                    <div className="px-4 pt-4">
                        {props.renderCoverageFields?.()}
                        <div className="mt-3">{props.renderReimbursementTerms?.()}</div>
                    </div>
                </div>
            )}

            {modalContent && props.iconHeaderVariant === "default" && renderCoverageDetailsButton()}
            {modal.render}
        </Card>
    );
}
