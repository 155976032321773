"use client";

import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/shared/utils";

const TooltipProvider = TooltipPrimitive.Provider;
const Tooltip = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;
const TooltipArrow = TooltipPrimitive.Arrow;

const tooltipContentVariants = cva(
    "border-type-disabled z-40 inline-flex size-full items-center justify-start gap-3 overflow-hidden break-words p-2 px-3 text-sm leading-5 animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
    {
        variants: {
            variant: {
                default: "rounded-md bg-background-primary text-content-primary shadow-md",
                pill: "rounded-full px-2 py-1 text-xs text-content-primary-invert"
            }
        },
        defaultVariants: {
            variant: "default"
        }
    }
);
export interface TooltipContentProps extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>, VariantProps<typeof tooltipContentVariants> {}

const TooltipContent = React.forwardRef<React.ElementRef<typeof TooltipPrimitive.Content>, TooltipContentProps>((props, ref) => {
    const { className, variant, sideOffset = 4, ...rest } = props;
    return <TooltipPrimitive.Content ref={ref} sideOffset={sideOffset} className={cn(tooltipContentVariants({ variant }), className)} {...rest} />;
});

TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider, TooltipArrow };
