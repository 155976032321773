import { Policy } from "@/shared/types/Quote.interface";
import { Label } from "@/shared/components/ui";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import Strings from "@/shared/utils/Strings.constants";

interface MultipetToggleProps {
    policies: Policy[] | undefined;
    fieldId?: string;
}

const MultipetToggle = (props: MultipetToggleProps) => {
    const { policies, fieldId = "all-pets" } = props;
    const { appState, updateAppState } = useAppLayerContext();

    const { isMultiPetLinked } = appState;
    const quoteHasMultiplePets = !!policies && policies.length > 1;
    return (
        <>
            {quoteHasMultiplePets && (
                <div className="flex items-center space-x-2">
                    <Checkbox
                        id={fieldId}
                        aria-labelledby={`${fieldId}-label`}
                        checked={isMultiPetLinked}
                        onCheckedChange={() => updateAppState({ isMultiPetLinked: !isMultiPetLinked })}
                        data-testid={fieldId}
                    />
                    <Label id={`${fieldId}-label`} htmlFor={fieldId} className="mb-0 bg-background-transparent text-xs text-content-secondary" allowLabelClick>
                        {Strings.MULTIPET_LINKED_LABEL}
                    </Label>
                </div>
            )}
        </>
    );
};

export default MultipetToggle;
