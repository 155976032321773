import * as React from "react";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { cva } from "class-variance-authority";
import { cn } from "@/shared/utils";
import { Badge } from "@/shared/components/ui/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangle } from "@fortawesome/sharp-solid-svg-icons";
export interface RadioButtonGroupProps {
    initialValue?: string;
    onValueChange?: (value: string) => void;
    options: { value: string; label: string | React.ReactNode; icon?: React.ReactNode; id?: string; tipText?: string }[];
    disabled?: boolean;
    error?: string;
    styles?: {
        item?: string;
        label?: string;
    };
    className?: string;
    variant?: "default" | "icon";
}

const radioGroupRoot = cva("flex w-full flex-row rounded-md text-xs text-content-primary disabled:cursor-wait", {
    variants: {
        variant: {
            default: "h-11",
            icon: "gap-6 px-1"
        }
    },
    defaultVariants: {
        variant: "default"
    }
});

const radioGroupItem = cva("flex cursor-pointer items-center justify-center whitespace-nowrap text-center focus:outline-none disabled:cursor-wait", {
    variants: {
        variant: {
            default: "flex min-w-0 flex-1 flex-col px-1",
            icon: "flex flex-col items-center"
        },
        state: {
            default: "border-2 border-stroke-transparent bg-background-secondary hover:bg-background-tertiary",
            selected: "border-2 border-stroke-brand-b bg-background-primary font-medium",
            iconDefault: "bg-background-primary text-content-inactive",
            iconSelected: "bg-background-primary text-content-primary"
        }
    },
    defaultVariants: {
        variant: "default",
        state: "default"
    }
});

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
    initialValue = "",
    onValueChange,
    options,
    className,
    error,
    disabled = false,
    variant = "default",
    styles
}) => {
    const [value, setValue] = React.useState(initialValue);
    const hasTipText = options.some(option => !!option?.tipText && option.tipText.length > 0);

    const handleChange = (newValue: string) => {
        if (newValue) {
            setValue(newValue);
            if (onValueChange) {
                onValueChange(newValue);
            }
        }
    };

    const conditionalWrapperClass = {
        "pb-9": !!hasTipText && !error
    };

    return (
        <div className={cn(conditionalWrapperClass, className)}>
            <ToggleGroup.Root type="single" value={value} onValueChange={handleChange} disabled={disabled} className={cn(radioGroupRoot({ variant }))}>
                {options.map((option, index) => {
                    const isSelected = value === option.value;
                    const variantState = variant === "icon" ? (isSelected ? "iconSelected" : "iconDefault") : isSelected ? "selected" : "default";
                    const tipText = option?.tipText;
                    const radioGroupItemClassConditions = {
                        "rounded-l-md": variant === "default" && index === 0,
                        "rounded-r-md": variant === "default" && index === options.length - 1,
                        "focus:rounded-none": variant === "default" && index !== 0 && index !== options.length - 1,
                        "shadow-faux-border-danger": !!error
                    };

                    return (
                        <ToggleGroup.Item
                            id={option.id}
                            key={option.value}
                            value={option.value}
                            disabled={disabled}
                            className={cn(radioGroupItem({ state: variantState, variant }), radioGroupItemClassConditions, styles?.item)}
                        >
                            {option.icon}
                            <label key={option.value} htmlFor={option.value} className={cn("mt-1 w-full cursor-pointer truncate text-sm leading-tight", styles?.label)}>
                                {option.label}
                            </label>
                            {!error && !!tipText && tipText.length > 0 && (
                                <div className="relative">
                                    <div className="absolute bottom-[-45px] left-1/2 flex -translate-x-1/2 flex-col items-center">
                                        <FontAwesomeIcon icon={faTriangle} className="mb-[-3px] scale-x-150 scale-y-100 text-content-success" size="xs" />
                                        <Badge className="bg-background-success-secondary py-1 leading-4">{tipText}</Badge>
                                    </div>
                                </div>
                            )}
                        </ToggleGroup.Item>
                    );
                })}
            </ToggleGroup.Root>
            {error && <p className="text-red-500 mt-1 text-sm">{error}</p>}
        </div>
    );
};

export default RadioButtonGroup;
