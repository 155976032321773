import { useEffect, useRef } from "react";
import { AnalyticsUtils } from "../utils/AnalyticsUtils";

interface UseDataDogViewNameProps {
    formId: string;
    formStepId: string;
    isEnabled: boolean;
}

export const useDataDogViewTracking = ({ formId, formStepId, isEnabled }: UseDataDogViewNameProps) => {
    const viewName = `${formId}|${formStepId}`;
    const lastViewName = useRef<string>("");

    useEffect(() => {
        if (isEnabled && viewName !== lastViewName.current) {
            lastViewName.current = viewName;
            AnalyticsUtils.startDataDogView(viewName);
        }
    }, [isEnabled, viewName]);
};
