import { ReactNode } from "react";
import { z } from "zod";
import { Species } from "spot-types/entities/Species";
import { RegexUtils } from "../utils/RegexUtils";
import { notificationSchema } from "./Notifications";
import Strings from "../utils/Strings.constants";

// Schema Definitions

export const UnknownExtraSchema = z.record(z.unknown());

const AmountSchema = z.object({
    value: z.number(),
    symbol: z.string()
});

export const FirstNameSchema = z
    .string()
    .transform(value => value.trim().replace(/\s+/g, " "))
    .refine(value => value.length > 0, { message: Strings.ERRORS.FIRST_NAME })
    .refine(value => value.length >= 2, { message: Strings.ERRORS.FIRST_NAME_LENGTH });

export const LastNameSchema = z
    .string()
    .transform(value => value.trim().replace(/\s+/g, " "))
    .refine(value => value.length > 0, { message: Strings.ERRORS.LAST_NAME })
    .refine(value => value.length >= 2, { message: Strings.ERRORS.LAST_NAME_LENGTH });

const CoverageTypeEnumSchema = z.enum(["accident", "illness", "peril", "wellness-gold", "wellness-platinum"]);

const CoverageAmountsSchema = z.object({
    reimbursementRate: z.number(),
    annualDeductible: z.number(),
    annualLimit: z.number()
});

const CoverageDiscountSchema = z.object({
    id: z.string().optional(),
    name: z.string().optional(),
    adjustment: z.number(),
    amount: z.number()
});

const CoverageTypeDifferencesSchema = z.object({
    type: CoverageTypeEnumSchema,
    startDate: z.string().optional()
});

const ExtraCoverageSettingsSchema = z
    .object({
        recId: z.number()
    })
    .deepPartial();

const CoverageTypeSchema = z.object({
    type: CoverageTypeEnumSchema,
    id: z.string().optional(),
    name: z.string().optional(),
    createDate: z.string().optional(),
    startDate: z.string().optional(),
    extra: ExtraCoverageSettingsSchema.and(UnknownExtraSchema).optional(),
    preventivePrice: z.number().optional()
});

const CoverageSettingsSchema = z.object({
    coverages: z.array(CoverageTypeSchema),
    amounts: CoverageAmountsSchema,
    extra: UnknownExtraSchema.optional()
});

const PartialCoverageSettingsSchema = CoverageSettingsSchema.deepPartial();

const ZipCodeOrPostalCodeSchema = z
    .string()
    .refine(value => value.length > 0, { message: Strings.ERRORS.ZIP })
    .refine(value => RegexUtils.zipRegex.test(value) || RegexUtils.postalCodeCARegex.test(value), {
        message: Strings.ERRORS.ZIP_INVALID
    });

const StreetSchema = z
    .string()
    .transform(value => value.trim().replace(/\s+/g, " "))
    .refine(value => value.length > 0, { message: Strings.ERRORS.ADDRESS })
    .refine(value => value.length <= 50, { message: Strings.ERRORS.ADDRESS_MAX })
    .refine(value => RegexUtils.hasLettersRegex.test(value), { message: Strings.ERRORS.ADDRESS_CHARS });

const AddressSchema = z.object({
    street1: StreetSchema,
    street2: z.string().max(50, Strings.ERRORS.ADDRESS_MAX).optional(),
    city: z
        .string()
        .transform(value => value.trim().replace(/\s+/g, " "))
        .refine(value => value.length > 0, { message: Strings.ERRORS.CITY })
        .refine(value => value.length <= 50, { message: Strings.ERRORS.CITY_MAX })
        .refine(value => RegexUtils.hasLettersRegex.test(value), { message: Strings.ERRORS.CITY_CHARS }),
    state: z
        .string()
        .transform(value => value.trim().replace(/\s+/g, " "))
        .refine(value => value.length > 0, { message: Strings.ERRORS.STATE })
        .refine(value => RegexUtils.hasLettersRegex.test(value), { message: Strings.ERRORS.STATE }),
    zipCode: ZipCodeOrPostalCodeSchema,
    country: z.string().optional()
});
const StateZipSchema = AddressSchema.pick({ state: true, zipCode: true });

export const MarketingSchema = z.object({
    hasSMSConsent: z.boolean().optional(),
    hasEmailConsent: z.boolean().optional()
});

const BillingFrequency = z.enum(["monthly", "yearly"]);
export type BillingFrequencyEnum = z.infer<typeof BillingFrequency>;

export const StripeBillingTypesSchema = z.enum(["card", "google_pay", "apple_pay"]);

export const BillingInfoSchema = z.object({
    firstName: FirstNameSchema,
    lastName: LastNameSchema,
    address: AddressSchema,
    nameOnCard: z
        .string()
        .min(3, { message: Strings.ERRORS.FIRST_LAST_NAMES })
        .transform(value => value.trim().replace(/\s+/g, " "))
        .refine(value => RegexUtils.hasLettersRegex.test(value), {
            message: Strings.ERRORS.FIRST_LAST_NAMES
        }),
    frequency: BillingFrequency
});

const ExtraPetSchema = z
    .object({
        microchipID: z
            .string()
            .optional()
            .refine(
                value => {
                    if (value === undefined || value === "") return true;
                    return RegexUtils.microchipRegex.test(value);
                },
                {
                    message: Strings.ERRORS.MICROCHIP
                }
            ),
        noPetChecked: z.boolean().optional()
    })
    .deepPartial();

export const PetSchema = z.object({
    id: z.string().optional(),
    name: z
        .string()
        .transform(value => value.trim().replace(/\s+/g, " "))
        .refine(value => value.length > 0, { message: Strings.ERRORS.PET_NAME })
        .refine(value => RegexUtils.petNameRegex.test(value), {
            message: Strings.ERRORS.PET_NAME_CHARS
        }),
    species: z.nativeEnum(Species),
    breedID: z.string().min(1, Strings.ERRORS.PET_BREED),
    gender: z.enum([`M`, `F`]),
    age: z.number({ required_error: Strings.ERRORS.PET_AGE }),
    birthMonth: z.string().min(1, Strings.ERRORS.BIRTH_MONTH).optional(),
    birthYear: z.string().min(4, Strings.ERRORS.BIRTH_YEAR).optional(),
    coverageSettings: PartialCoverageSettingsSchema.optional(),
    extra: ExtraPetSchema.and(UnknownExtraSchema).optional()
});

export const PartialPetSchema = PetSchema.deepPartial();
export type InitialPetValues = z.infer<typeof PartialPetSchema>;

export const PolicySchema = PetSchema.extend({
    basePrice: AmountSchema,
    discountAmount: AmountSchema.optional(),
    finalPrice: AmountSchema,
    coverageSettings: CoverageSettingsSchema,
    discounts: z.array(CoverageDiscountSchema).optional(),
    discountTotalPercentage: z.number().optional()
});

export const PartialPolicySchema = PetSchema.merge(PolicySchema.deepPartial());

export const OwnerSchema = z.object({
    firstName: FirstNameSchema,
    lastName: LastNameSchema,
    email: z.string().min(1, Strings.ERRORS.EMAIL.MIN).max(50, Strings.ERRORS.EMAIL.MAX).email(Strings.ERRORS.EMAIL_INVALID),
    ratingZipcode: ZipCodeOrPostalCodeSchema
});

export const DebounceResponseSchema = z.object({ typoFixSuggestion: z.string().optional(), userResponse: z.boolean().optional() }).optional();
export type DebounceObject = z.infer<typeof DebounceResponseSchema>;
export const DebounceRecordSchema = z.record(DebounceResponseSchema);
export type DebounceRecord = z.infer<typeof DebounceRecordSchema>;

export const ExtraQuoteSchema = z
    .object({
        lastStepID: z.string(),
        thankYouURL: z.string(),
        paymentURL: z.string(),
        policyStepConsented: z.boolean(),
        termsConsent: z.boolean(),
        modalStates: z.record(z.any()),
        formData: z.record(z.any()),
        notifications: notificationSchema.array().optional(),
        rewards: z.any().optional(),
        saasquatch: z.record(z.any()).optional(),
        debounce: DebounceRecordSchema,
        testUserId: z.string().optional(),
        queryParams: z.record(z.any()).optional()
    })
    .deepPartial();

export const QuoteSchema = OwnerSchema.extend({
    id: z.string().regex(RegexUtils.uuidRegex),
    underwriter: z.enum([`ptz-us`, `ptz-ca`]),
    phone: z.string().regex(RegexUtils.phoneRegex, "Enter a valid mobile number").optional(),
    policies: z.array(PolicySchema),
    lastStepID: z.string().optional(),
    billingInfo: BillingInfoSchema,
    discountCode: z.string().nullable().optional(),
    affiliateCode: z.string().nullable().optional(),
    quoteStatus: z.enum([`open`, `failed-authorization`, `finalize-processing`, `finalized`]),
    transactionFee: AmountSchema.optional(),
    accountID: z.string().optional(),
    ratingAddress: StateZipSchema,
    marketing: MarketingSchema.optional(),
    paperless: z.boolean().optional(),
    extra: ExtraQuoteSchema.and(UnknownExtraSchema).optional()
});
export const PartialQuoteSchema = QuoteSchema.deepPartial();

export const BillingStepSchema = z.object({
    firstName: FirstNameSchema,
    lastName: LastNameSchema,
    billingInfo: BillingInfoSchema,
    phone: z.string().regex(RegexUtils.phoneRegex, `Enter a valid mobile number`),
    paperless: z.boolean(),
    extra: z.object({
        termsConsent: z.coerce.boolean().refine(value => value === true, {
            message: Strings.ERRORS.CONTACT_CONSENT
        })
    })
});

export const ExtendedBillingStepSchema = BillingStepSchema.merge(OwnerSchema.omit({ ratingZipcode: true }));

export const StripeExpressPaymentSchema = z.object({
    billingInfo: BillingInfoSchema.pick({ firstName: true, lastName: true }),
    paperless: z.boolean(),
    extra: z.object({
        termsConsent: z.coerce.boolean().refine(value => value === true, {
            message: Strings.ERRORS.CONTACT_CONSENT
        })
    })
});

export const PartialBillingStepSchema = ExtendedBillingStepSchema.deepPartial();
export type PartialBillingStepType = z.infer<typeof PartialBillingStepSchema>;

// Underwriter Schema
export const UnderwriterSchema = z.enum([`ptz-us`, `ptz-ca`]);
export const UnderwriterConfigSchema = z.enum([`PTZ_US`, `PTZ_CA`]);

// Types
export type CoverageType = z.infer<typeof CoverageTypeSchema>;
export type CoverageTypeEnum = z.infer<typeof CoverageTypeEnumSchema>;
export type CoverageAmounts = z.infer<typeof CoverageAmountsSchema>;
export type CoverageSettings = z.infer<typeof PartialCoverageSettingsSchema>;
export type BillingInfo = z.infer<typeof BillingInfoSchema>;
export type Owner = z.infer<typeof OwnerSchema>;
export type Pet = z.infer<typeof PetSchema>;
export type Policy = z.infer<typeof PartialPolicySchema>;
export type PolicyFinalized = z.infer<typeof PolicySchema>;
export type Quote = z.infer<typeof PartialQuoteSchema>;
export type QuoteFinalized = z.infer<typeof QuoteSchema>;
export type UnderwriterConfigKey = z.infer<typeof UnderwriterConfigSchema>;

export interface SimpleOption {
    label: string;
    value: string;
}

export interface Option {
    label: string;
    value: any;
    icon?: ReactNode;
    tipText?: string;
}

export type FormattedPolicy = Policy & {
    petAge: string;
    discountsAmount: string;
    annualLimit: string;
    annualDeductible: string;
    reimbursementRate: string;
    accidentCreateDate: string;
    accidentCoverageDate: string;
    illnessCoverageDate: string;
    perilIllnessCoverageDate: string;
    wellnessCoverageDate?: string;
    wellnessCoveragePrice?: number;
    hasWellnessCoverage: boolean;
    isAccidentOnly: boolean;
};

export type DefaultPolicyAmounts = { annualLimit?: number; annualDeductible?: number; reimbursementRate?: number };

export type KeyValuePair = {
    key: string;
    value?: string | null;
};
