import { PublicConfig } from "../PublicConfig";
import { Option } from "../types/Quote.interface";
import { Species } from "spot-types/entities/Species";

export interface State {
    label: string;
    value: string;
}

export const MAX_PET_NAME_LABEL = 20;
export const MAX_PET_NAME = 50;

export const QUOTE_VERSIONS: Option[] = [
    {
        label: "US",
        value: "forms/quote-ptz-us"
    },
    {
        label: "CA",
        value: "forms/quote-ptz-ca"
    },
    {
        label: "Fallback",
        value: "forms/quote-fallback-us"
    },
    {
        label: "Retarget",
        value: "forms/quote-retarget-us"
    }
];

export const LABEL_VARIANTS: Option[] = [
    {
        label: "Default",
        value: "default"
    },
    {
        label: "Floating",
        value: "floating"
    }
];

export const TOP_BREEDS = ["Giant Mix (over 100 pounds)", "Large Mix (51-100 pounds)", "Medium Mix (26-50 pounds)", "Small Mix (11-25 pounds)", "Small Mix (up to 10 pounds)"];
export const TOP_BREEDS_CA = ["Mixed Breed, Large (over 44 lbs fully grown)", "Mixed Breed, Medium (up to 44 lbs fully grown)", "Mixed Breed, Small (under 24lbs fully grown)"];

export const US_STATES: State[] = [
    {
        label: "Alabama",
        value: "AL"
    },
    {
        label: "Alaska",
        value: "AK"
    },
    {
        label: "American Samoa",
        value: "AS"
    },
    {
        label: "Arizona",
        value: "AZ"
    },
    {
        label: "Arkansas",
        value: "AR"
    },
    {
        label: "California",
        value: "CA"
    },
    {
        label: "Colorado",
        value: "CO"
    },
    {
        label: "Connecticut",
        value: "CT"
    },
    {
        label: "Delaware",
        value: "DE"
    },
    {
        label: "District Of Columbia",
        value: "DC"
    },
    {
        label: "Federated States Of Micronesia",
        value: "FM"
    },
    {
        label: "Florida",
        value: "FL"
    },
    {
        label: "Georgia",
        value: "GA"
    },
    {
        label: "Guam",
        value: "GU"
    },
    {
        label: "Hawaii",
        value: "HI"
    },
    {
        label: "Idaho",
        value: "ID"
    },
    {
        label: "Illinois",
        value: "IL"
    },
    {
        label: "Indiana",
        value: "IN"
    },
    {
        label: "Iowa",
        value: "IA"
    },
    {
        label: "Kansas",
        value: "KS"
    },
    {
        label: "Kentucky",
        value: "KY"
    },
    {
        label: "Louisiana",
        value: "LA"
    },
    {
        label: "Maine",
        value: "ME"
    },
    {
        label: "Marshall Islands",
        value: "MH"
    },
    {
        label: "Maryland",
        value: "MD"
    },
    {
        label: "Massachusetts",
        value: "MA"
    },
    {
        label: "Michigan",
        value: "MI"
    },
    {
        label: "Minnesota",
        value: "MN"
    },
    {
        label: "Mississippi",
        value: "MS"
    },
    {
        label: "Missouri",
        value: "MO"
    },
    {
        label: "Montana",
        value: "MT"
    },
    {
        label: "Nebraska",
        value: "NE"
    },
    {
        label: "Nevada",
        value: "NV"
    },
    {
        label: "New Hampshire",
        value: "NH"
    },
    {
        label: "New Jersey",
        value: "NJ"
    },
    {
        label: "New Mexico",
        value: "NM"
    },
    {
        label: "New York",
        value: "NY"
    },
    {
        label: "North Carolina",
        value: "NC"
    },
    {
        label: "North Dakota",
        value: "ND"
    },
    {
        label: "Northern Mariana Islands",
        value: "MP"
    },
    {
        label: "Ohio",
        value: "OH"
    },
    {
        label: "Oklahoma",
        value: "OK"
    },
    {
        label: "Oregon",
        value: "OR"
    },
    {
        label: "Palau",
        value: "PW"
    },
    {
        label: "Pennsylvania",
        value: "PA"
    },
    {
        label: "Puerto Rico",
        value: "PR"
    },
    {
        label: "Rhode Island",
        value: "RI"
    },
    {
        label: "South Carolina",
        value: "SC"
    },
    {
        label: "South Dakota",
        value: "SD"
    },
    {
        label: "Tennessee",
        value: "TN"
    },
    {
        label: "Texas",
        value: "TX"
    },
    {
        label: "Utah",
        value: "UT"
    },
    {
        label: "Vermont",
        value: "VT"
    },
    {
        label: "Virgin Islands",
        value: "VI"
    },
    {
        label: "Virginia",
        value: "VA"
    },
    {
        label: "Washington",
        value: "WA"
    },
    {
        label: "West Virginia",
        value: "WV"
    },
    {
        label: "Wisconsin",
        value: "WI"
    },
    {
        label: "Wyoming",
        value: "WY"
    }
];

export const US_FOOTER_LINKS = [
    { label: "Terms & Conditions", href: `${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/terms-conditions` },
    { label: "Privacy", href: `${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/privacy` },
    { label: "Sources & Citations", href: `${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/sources-and-citations` },
    { label: "Agency Information", href: `${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/agency-info` },
    { label: "Sample Policy", href: `${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/spot-sample-policy.pdf` },
    { label: "ADA Notice", href: `${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/ada-compliance-notice` },
    { label: "Sitemap", href: `${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/sitemap.xml` }
];

export const US_POLICY_FACTS = [
    "You can use any licensed veterinarian in the US or Canada, including specialists and emergency vets!",
    "When submitting a claim, include your itemized vet bill and medical records for quicker reimbursement!",
    "Access the 24/7 VetAccess Helpline in your Member Center to call or chat with a veterinary professional."
];

export const COVERAGE_ICON_CONTENT = {
    "needs-content": {
        definition: "This item needs content.",
        value: "This item needs content.",
        fact: "This item needs content."
    },
    "vet-exam-fees": {
        title: "Vet Exam Fees",
        definition: "Charges for veterinary consultations or lab tests to diagnose or treat an illness or injury.",
        value: "Exams and tests for canine and feline issues like coughs, sudden lethargy, and other medical issues.",
        fact: "Many providers exclude exam fees from coverage or require an additional fee to cover them."
    },
    "prescription-medications": {
        title: "Prescription Medications",
        definition: "Medicines and foods that are prescribed by a veterinarian to help treat an illness or condition.",
        value: "Examples include prescriptions for anti-inflammatory medication for arthritis, or antiviral drugs for feline herpesvirus. Note: Prescription food & supplements are not covered by a Spot plan if they are used for weight management or general health maintenance.",
        fact: "Prescription medications and food are essential to managing certain conditions."
    },
    "x-rays-tests": {
        title: "X-rays & Tests",
        definition: "Imaging and laboratory tests used to diagnose medical conditions.",
        value: "X-rays are usually required for suspected broken bones, and CT scans are essential for detecting and measuring cancer.",
        fact: "Diagnostic testing is involved in almost every unexpected vet visit."
    },
    "skin-eye-ear-infections": {
        title: "Skin, Eye, & Ear Infections",
        definition: "Medical treatments for infections affecting the skin, eyes, or ears.",
        value: "Ear infections,  conjunctivitis, and pyoderma are common issues seen in many of our furry friends.",
        fact: "These infections can lead to more serious conditions if untreated."
    },
    "cancer-chemotherapy": {
        title: "Cancer & Chemotherapy",
        definition: "Medical care related to the treatment of tumors.",
        value: "Chemotherapy and surgery are important parts of treating the many types of cancer that affect our pets.",
        fact: "Growths & lumps were the #8 submitted claim last year, totaling over $3M."
    },
    "surgery-specialized-care": {
        title: "Surgery & Specialized Care",
        definition: "Operations and treatments performed by licensed veterinary specialists.",
        value: "Neurosurgery and specialized care can quickly address otherwise complicated health problems. ",
        fact: "Reproductive conditions, allergies, and arthritis are common claims that may require a specialist visit."
    },
    "congenital-conditions-heart-disease": {
        title: "Congenital & Heart Conditions",
        definition: "Conditions that are genetically inherited or present at birth.",
        value: "Hereditary hip dysplasia,  congenital heart defects, and patellar luxation can greatly affect our pets’ quality of life.",
        fact: "Heart murmurs, reproductive conditions, and diabetes were some of the most common hereditary condition claims submitted in 2023."
    },
    "emergencies-hospitalizations": {
        title: "Emergencies & Hospitalizations",
        definition: "Care needed for sudden, unexpected illnesses or injuries requiring urgent veterinary attention.",
        value: "Every year, we see many emergencies that require surgery, such as car accidents and toxin ingestion.",
        fact: "In 2023 alone, over $7.3M in claims were submitted for foreign body and toxin ingestions."
    },
    "microchip-implantation": {
        title: "Microchip Implantation",
        definition: "Inserting an identification chip beneath a pet's skin.",
        value: "Your pet can be microchipped as part of a spay/neuter appointment, or at a routine wellness checkup.",
        fact: "Microchipped dogs have a better chance at being reunited with their owners. Microchipping was the #4 most submitted claim in 2023."
    },
    "excessive-licking-fur-pulling": {
        title: "Excessive Licking & Fur Pulling",
        definition: "Behavioral or medical condition where pets excessively groom.",
        value: "Excessive grooming often leading to bald spots or sores, potentially causing pain and more serious illnesses to develop.",
        fact: "Treatment for these issues can prevent more expensive illnesses farther down the line."
    },
    "dental-illnesses-gum-disease": {
        title: "Dental Illnesses & Gum Disease",
        definition: "Treatments for dental and periodontal diseases.",
        value: "Includes tooth extraction surgery for periodontal disease and stomatitis treatment for severe mouth inflammation.",
        fact: "Periodontal disease was our top dental illness claim in 2023, totaling over $2.5M."
    },
    "digestive-illnesses": {
        title: "Digestive Illnesses",
        definition: "Disorders of the gastrointestinal system.",
        value: "Treatments for pancreatitis and diarrhea are a common issue in both dogs and cats.",
        fact: "Gastrointestinal issues ranked among the top 10 insurance claims for pets in 2023."
    },
    "hip-dysplasia": {
        title: "Hip Dysplasia",
        definition: "Inflammatory joint conditions and hip malformations.",
        value: "Includes management of hip dysplasia in dogs and arthritis treatments for reduced mobility in cats.",
        fact: "Arthritis and hip dysplasia are especially common in older and large-breed pets."
    },
    "infectious-diseases-diseases-from-parasites": {
        title: "Diseases: Infectious & from Parasites",
        definition: "Treatments for illnesses from infectious agents and parasites.",
        value: "Coverage includes Lyme disease treatment for dogs and feline leukemia virus management for cats.",
        fact: "Spot received over 1,700 claims for parasite-related issues in 2023."
    },
    "orthopedic-injuries": {
        title: "Orthopedic Injuries",
        definition: "Musculoskeletal injuries affecting bones and joints.",
        value: "Coverage includes pain management for fractures, as well as casting for broken limbs.",
        fact: "Spot policyholders submitted claims totaling over $3.8M for cruciate ligament injuries and fractures in 2023."
    },
    "swallowed-objects-poisons": {
        title: "Swallowed Objects & Poisons",
        definition: "Incidents requiring intervention for ingestion of harmful items or toxins.",
        value: "Includes endoscopic removal of swallowed toys and treatment for toxic plant ingestion.",
        fact: "Claims for ingested foreign objects and toxins totaled over $7.3M in 2023."
    },
    "allergies-treatments": {
        title: "Allergies & Treatments",
        definition: "Allergies and treatments for immune reactions to allergens.",
        value: "Covers treatments for food and  environmental allergies that affect both dogs and cats.",
        fact: "Allergy treatments vary in cost and often involve special diets or medications, which are sometimes not covered by pet insurance."
    },
    "urinary-tract-infections": {
        title: "Urinary Tract Infections",
        definition: "Bacterial infections in the urinary system.",
        value: "UTI treatments for frequent urination in dogs and urinary straining in cats.",
        fact: "UTIs are common in older pets, and treatment may include antibiotics and lifestyle adjustments."
    },
    "virtual-vet-visits": {
        title: "Virtual Vet Visits",
        definition: "Telecommunication-based vet consultations.",
        value: "Treatments include telemedicine for minor skin issues, or virtual follow-ups for post-surgical recovery.",
        fact: "Essential for providing convenient access to veterinary advice."
    },
    "end-of-life-expenses": {
        title: "End of Life Expenses",
        definition: "Costs for end-of-life care.",
        value: "Euthanasia for pets with terminal conditions like advanced cancer in dogs or kidney disease in cats.",
        fact: "End-of-life expenses can add up, yet many insurance plans do not cover them, making financial planning important."
    },

    ///////////// Accident only /////////////////

    "mri-or-ct-scans-x-rays": {
        title: "MRI or CT Scans & X-rays",
        definition: "Diagnostic imaging for medical assessment.",
        value: "X-rays for suspected fractures in dogs and CT scans for cancer in cats.",
        fact: "Diagnostic tests are crucial but often not fully covered by standard plans."
    },
    "surgery-hospitalization": {
        title: "Surgery & Hospitalization",
        definition: "Surgical interventions and post-operative hospital care.",
        value: "Treatments include ACL repair surgery for dogs and foreign object removal surgery in cats.",
        fact: "Surgeries can be costly, and not all insurance plans cover the full expenses."
    },
    "fluids-medications": {
        title: "IV Fluids & Medications",
        definition: "Intravenous fluids and medications for recovery and stabilization.",
        value: "Treatments can include IV fluids for dehydration, or issues like kidney treatment.",
        fact: "Necessary for emergency and surgery recovery but not always covered by standard plans."
    },
    "medical-supplies": {
        title: "Medical Supplies",
        definition: "Supplies like bandages, splints, and wound care.",
        value: "Bandages for a dog injured by broken glass or a cat with a minor burn.",
        fact: "Often part of treatment but may incur out-of-pocket costs if not covered."
    },
    "poison-control-fees": {
        title: "Poison Control Consultation Fees",
        definition: "Fees for poison control assistance after toxin ingestion.",
        value: "Consultation for a dog that ate chocolate or a cat that ingested a toxic plant.",
        fact: "Poison control consultations are critical in emergencies, yet may come with fees."
    },
    "prescription-medications-ao": {
        title: "Prescription Medications",
        definition: "Vet-prescribed medications for accidents and injuries.",
        value: "Antibiotics for a dog’s cut or pain meds for a cat’s minor fracture.",
        fact: "Essential for recovery but not always fully covered by insurance."
    },
    "broken-teeth": {
        title: "Broken Teeth",
        definition: "Treatment for dental injuries from accidents.",
        value: "Tooth extraction for broken teeth after chewing  on a bone or cracking a tooth in a fall.",
        fact: "Dental care for accidents can be costly and often isn’t covered."
    },
    "advanced-care": {
        title: "Advanced Care",
        definition: "Specialized treatments for complex health issues.",
        value: "Orthopedic surgery for fractures in dogs or oncologist care for toxin exposure in cats.",
        fact: "Advanced treatments are often essential but costly and may not be fully covered."
    },
    "alternative-treatments": {
        title: "Alternative Treatments",
        definition: "Non-traditional therapies for recovery and pain management.",
        value: "Acupuncture for post-injury pain or laser therapy for inflammation are both alternative treatments that may be covered.",
        fact: "This can be effective for recovery but is often not covered by insurance."
    }
};

// Recommended coverage options by age
// Opting for duplicate data to allow for future customization (different labels for different ages etc)
// Todo: If this test wins, add tipText strings to Strings file
export const RECOMMENDED_OPTION_LABELS = {
    [Species.Dog]: [
        { age: 0, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for puppies" },
        { age: 1, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for puppies" },
        { age: 2, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for puppies" },
        { age: 3, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 3-5" },
        { age: 4, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 3-5" },
        { age: 5, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 3-5" },
        { age: 6, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 6-9" },
        { age: 7, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 6-9" },
        { age: 8, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 6-9" },
        { age: 9, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 6-9" },
        { age: 10, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 10+" },
        { age: 11, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 10+" },
        { age: 12, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 10+" },
        { age: 13, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 10+" },
        { age: 14, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 10+" },
        { age: 15, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 10+" },
        { age: 16, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 10+" },
        { age: 17, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 10+" },
        { age: 18, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 10+" },
        { age: 19, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 10+" },
        { age: 20, reimbursement: "80", annualLimit: "3000", deductible: "250", tipText: "Our pick for dogs ages 10+" }
    ],
    [Species.Cat]: []
};
