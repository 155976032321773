import React, { useState, KeyboardEvent, useRef } from "react";

// Media Icons

// Components
import { PriceInfo } from "../PriceInfo";
import { RadioButtonGroup } from "../ui/RadioButtonGroup";
import { Badge } from "../ui/Badge";
import { Button } from "../ui/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faDog, faCat, faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { faPaw, faPencil } from "@fortawesome/pro-solid-svg-icons";
import PetModal from "../PetModal";

// Types
import { Option } from "@/shared/types/Quote.interface";
import { Species } from "spot-types/entities/Species";
import { MultiPetSelectProps } from "./types";

// Context
import { useAppLayerContext } from "@/shared/contexts/AppLayer";

// Hooks
import { useIntersectionObserver } from "@/shared/hooks/useIntersectionObserver";
import { useDeepCompareMemo } from "@react-hookz/web";
import { useBreeds } from "@/shared/hooks/useBreeds";
import PetDropdown from "./MultiPetSelectDropdown/PetDropdown";
import { cn } from "@/shared/utils";
import { StickyMenu } from "./StickyMenu";
import { useCoverageEditorContext } from "../CoverageEditor";
import useIsOverflowing from "@/shared/hooks/useIsOverflowing";
import Strings from "@/shared/utils/Strings.constants";

const MultiPetSelectHybrid: React.FC<Omit<MultiPetSelectProps, "variant">> = ({
    activePet,
    handleActivePetChange,
    isQuoteUpdating,
    isMultiPetLinked,
    priceInfoData,
    policiesCount,
    petModalConfig,
    policies,
    isApplyAllHidden
}) => {
    const { appState } = useAppLayerContext();
    const { underwriter } = appState;
    const editorConfig = useCoverageEditorContext();
    const { getModalInitialValues } = editorConfig;
    const { ref: addAPetRef, isIntersecting } = useIntersectionObserver();
    const isAddAPetInView = isIntersecting ?? true;
    const defaultCoverageSettings = policies?.[0]?.coverageSettings;

    const allPets = useDeepCompareMemo(() => policies ?? [], [policies]);
    const activePetIndex = allPets.findIndex(pet => pet.id === activePet?.id);

    const [isOverflowing, setIsOverflowing] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useIsOverflowing(containerRef, setIsOverflowing, "width");

    // Create policy pets options
    const policyPets: Option[] = allPets.map(pet => {
        const isActivePet = !!pet.id && activePet.id === pet.id;
        const isCat = pet.species === Species.Cat;
        const icon = <FontAwesomeIcon icon={isCat ? faCat : faDog} className={cn(isActivePet ? "text-content-primary" : "text-content-inactive", "size-8 shrink-0 p-1")} />;
        return { value: pet.id ?? ``, label: pet.name ?? ``, icon };
    });

    const singlePetQuote = !!policies && policies.length === 1;
    const petAge = activePet?.age;
    const currentAge = `${petAge === 0 ? "Under 1" : petAge} y${petAge && petAge > 1 ? "/" : "/"}o`;
    const { breeds } = useBreeds(underwriter);
    const breedName = breeds?.find(breed => breed.id === activePet?.breedID);
    const totalPoliciesCount = policiesCount ?? 0;

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    // Handlers for modal
    const handleAddPet = () => setIsAddModalOpen(true);
    const handleEditPet = () => setIsEditModalOpen(true);

    const handleCloseAddModal = () => setIsAddModalOpen(false);
    const handleCloseEditModal = () => setIsEditModalOpen(false);

    // State for dropdwon
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const handleDropdownToggle = () => setDropdownOpen(!dropdownOpen);

    return (
        <>
            <StickyMenu isVisible={!isAddAPetInView} />
            <div ref={addAPetRef}>
                {isMultiPetLinked && !isApplyAllHidden && totalPoliciesCount > 1 ? (
                    <div className="flex flex-col md:flex-row">
                        <div className="flex flex-row items-center justify-between">
                            <PetDropdown
                                policyPets={policyPets}
                                handleActivePetChange={handleActivePetChange}
                                petModalConfig={petModalConfig}
                                isMultiPetLinked={isMultiPetLinked}
                                triggerButton={
                                    <button type="button" onClick={handleDropdownToggle}>
                                        <Badge className="text-md cursor-pointer rounded-md bg-background-brand-b-secondary px-4 py-3 text-center text-base leading-5 text-content-brand-b">
                                            <div className="flex items-center gap-1">
                                                <FontAwesomeIcon icon={faPaw} className="size-4 shrink-0 p-1" />
                                                <span>{totalPoliciesCount} Pets</span>
                                                <FontAwesomeIcon icon={faCircleInfo} className="size-4 shrink-0 p-1" />
                                            </div>
                                        </Badge>
                                    </button>
                                }
                                showEditButton
                                showFooter
                            />
                            <div className="flex flex-col justify-end gap-1 md:hidden">
                                <PriceInfo
                                    variant="multi-pet"
                                    petId={activePet?.id}
                                    totalPrice={priceInfoData.roundedPrice}
                                    discountsAmount={priceInfoData.discountsAmount}
                                    policiesCount={policiesCount ?? 0}
                                />
                            </div>
                        </div>
                        <div className="mt-4 flex justify-center md:ml-4 md:mt-3">
                            <PetModal
                                initialValues={{ policies: [getModalInitialValues(defaultCoverageSettings)] }}
                                triggerButton={
                                    <Button
                                        loading={isQuoteUpdating}
                                        onClick={handleAddPet}
                                        onTouchStart={handleAddPet}
                                        onKeyDown={e => {
                                            if (e.key === "Enter" || e.key === " ") {
                                                e.preventDefault();
                                                handleAddPet();
                                            }
                                        }}
                                        variant="outline-secondary"
                                        size="sm"
                                        className="text-xs"
                                        startDecorator={<FontAwesomeIcon icon={faPlus} className="size-4" />}
                                    >
                                        {Strings.ADD_PET_GET_DISCOUNT}
                                    </Button>
                                }
                                isOpen={isAddModalOpen}
                                handleOpen={() => setIsAddModalOpen(true)}
                                onClose={handleCloseAddModal}
                                config={petModalConfig}
                                handleActivePetChange={handleActivePetChange}
                            />
                        </div>
                        <div className="hidden md:ml-auto md:flex md:justify-end md:gap-1">
                            <PriceInfo
                                variant="multi-pet"
                                petId={activePet?.id}
                                totalPrice={priceInfoData.roundedPrice}
                                discountsAmount={priceInfoData.discountsAmount}
                                policiesCount={policiesCount ?? 0}
                            />
                        </div>
                    </div>
                ) : (
                    //////Single Pet
                    <>
                        <div className="flex flex-row items-center gap-6 overflow-x-auto contain-layout">
                            <div ref={containerRef} className="flex overflow-x-auto py-2">
                                <RadioButtonGroup
                                    initialValue={policyPets[activePetIndex]?.value as string}
                                    onValueChange={value => handleActivePetChange(value)}
                                    options={policyPets}
                                    // disabled={isQuoteUpdating}
                                    variant="icon"
                                    styles={{ item: "min-w-[40px] max-w-[64px] px-1", label: cn("text-xs") }}
                                />
                            </div>
                            <div className="relative flex shrink-0 cursor-pointer flex-col items-center justify-center">
                                <PetModal
                                    initialValues={{ policies: [getModalInitialValues(defaultCoverageSettings)] }}
                                    triggerButton={
                                        <button
                                            type="button"
                                            disabled={isQuoteUpdating}
                                            onClick={handleAddPet}
                                            onTouchStart={handleAddPet}
                                            className="group z-10 focus:shadow-none"
                                            onKeyDown={(e: KeyboardEvent) => {
                                                if (e.key === "Enter" || e.key === " ") {
                                                    e.preventDefault();
                                                    handleAddPet();
                                                }
                                            }}
                                        >
                                            <div className="group flex size-[28px] items-center justify-center rounded-full border-2 border-content-brand hover:border-content-brand-b group-focus:border-content-brand-b group-focus:outline-none">
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                    className="size-4 shrink-0 p-1 text-content-brand group-hover:text-content-brand-b group-focus:text-content-brand-b"
                                                />
                                            </div>
                                        </button>
                                    }
                                    isOpen={isAddModalOpen}
                                    handleOpen={() => setIsAddModalOpen(true)}
                                    onClose={handleCloseAddModal}
                                    config={petModalConfig}
                                    handleActivePetChange={handleActivePetChange}
                                />
                                {isOverflowing && <div className="blur-from-right pointer-events-none absolute -top-4 right-0 z-0 size-[60px]" />}
                            </div>

                            {singlePetQuote && (
                                <Badge variant="secondary" className="h-[40px] w-[156px] rounded-md text-center text-xs leading-5">
                                    10% multi-pet discount on all additional pets
                                </Badge>
                            )}
                        </div>
                        <div className="flex flex-row items-center justify-between">
                            <div className="flex max-w-[50%] flex-col">
                                <span className="truncate text-xs leading-5">
                                    {currentAge}, {breedName?.name}
                                </span>
                                <div className="mt-1 flex cursor-pointer items-center">
                                    <PetModal
                                        initialValues={{ policies: [activePet] }}
                                        triggerButton={
                                            <button
                                                type="button"
                                                onClick={handleEditPet}
                                                onTouchStart={handleEditPet}
                                                onKeyDown={(e: KeyboardEvent) => {
                                                    if (e.key === "Enter" || e.key === " ") {
                                                        e.preventDefault();
                                                        handleEditPet();
                                                    }
                                                }}
                                                className="flex cursor-pointer items-center gap-1"
                                            >
                                                <FontAwesomeIcon icon={faPencil} className="size-4 shrink-0 p-1 text-content-secondary" />
                                                <label className="cursor-pointer text-xs font-bold text-content-secondary">{Strings.EDIT_PET_DETAILS}</label>
                                            </button>
                                        }
                                        isOpen={isEditModalOpen}
                                        handleOpen={() => setIsEditModalOpen(true)}
                                        onClose={handleCloseEditModal}
                                        config={petModalConfig}
                                        handleActivePetChange={handleActivePetChange}
                                        key={activePet.id}
                                    />
                                </div>
                            </div>
                            <div className="-mt-2 flex flex-col justify-end gap-1">
                                <PriceInfo
                                    variant="multi-pet"
                                    petId={activePet?.id}
                                    totalPrice={priceInfoData.roundedPrice}
                                    discountsAmount={priceInfoData.discountsAmount}
                                    policiesCount={policiesCount ?? 0}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default MultiPetSelectHybrid;
