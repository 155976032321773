// packages
import React from "react";
import { useWindowSize } from "@react-hookz/web";
// components
import PreventiveCard from "./PreventiveCard";
// utils
import { IconUtils } from "@/shared/utils/IconUtils";
import { PreventiveCoverageType, PreventiveOption, PreventiveConfig } from "@/shared/utils/CoverageUtils";
// media
import GoldPreventive from "@/shared/media/images/gold-preventive.svg";
import PlatinumPreventive from "@/shared/media/images/platinum-preventive.svg";
// types
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { Carousel, CarouselContent, CarouselItem, CarouselNavDots } from "@/shared/components/ui/carousel";
import { Pet, Quote } from "@/shared/types/Quote.interface";
import { useStatsig } from "@/shared/hooks/useStatsig";
import { PublicConfig } from "@/shared/PublicConfig";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";
import { useFormParentContext } from "@/shared/contexts/FormParent";

interface PreventiveIconGridProps {
    pet: Pet;
    preventiveOptions: PreventiveOption[];
    preventiveConfig: PreventiveConfig;
    underwriter: PetUnderwriterType;
    wellnessLevel: string;
    handleChange: (coverageLevel: PreventiveCoverageType) => void;
}

export function PreventiveCards({ pet, preventiveOptions, preventiveConfig, underwriter, wellnessLevel, handleChange }: PreventiveIconGridProps) {
    const goldBenefits = IconUtils.getPreventiveGoldBenefits(underwriter, pet.age);
    const platinumBenefits = IconUtils.getPreventivePlatinumBenefits(underwriter, pet.age);
    const totalGoldBenefits = goldBenefits.reduce((acc, curr) => acc + curr.amount!, 0);
    const goldLevel = preventiveOptions.find((option: PreventiveOption) => option.value === "wellness-gold");
    const platinumLevel = preventiveOptions.find((option: PreventiveOption) => option.value === "wellness-platinum");

    const { watch } = useFormParentContext<Quote>();
    const quote = watch();
    // Statsig - recommend-prev-gold-cats
    const { recommendPrevGoldCats } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_CAT_EXPERIMENTS,
        layerParameters: [{ key: "recommendPrevGoldCats", defaultValue: false }] as const,
        queryParams: AnalyticsUtils.getQueryParams(quote?.extra?.queryParams)
    });
    // Statsig:end

    const { width } = useWindowSize();

    const isDefault = recommendPrevGoldCats && underwriter === "ptz-us" && pet.species === "Cat" ? true : false;

    const showGoldFirst = isDefault;

    const handleCardClick = (coverageLevel: PreventiveCoverageType) => {
        if (coverageLevel === wellnessLevel) {
            handleChange("None");
        } else {
            handleChange(coverageLevel);
        }
    };

    return (
        <Carousel disabled={width >= 768}>
            <CarouselContent className="flex pb-2" contentWrapperClass="-mx-4 px-4 sm:-mx-6 sm:px-6 md:mx-0 md:px-0">
                {showGoldFirst ? (
                    <>
                        <CarouselItem className="basis-[99%] pl-1 md:order-1 md:basis-1/2 md:pl-0">
                            <PreventiveCard
                                pet={pet}
                                price={goldLevel?.price}
                                label={preventiveConfig.labels.basic}
                                image={<GoldPreventive />}
                                benefits={goldBenefits}
                                currentWellnessLevel={wellnessLevel}
                                wellnessLevel={"wellness-gold"}
                                onChange={() => handleCardClick("wellness-gold")}
                                totalBenefitsValue={totalGoldBenefits}
                                showRecommendedAge={isDefault}
                                recommendPrevGoldCats={isDefault}
                            />
                        </CarouselItem>
                        <CarouselItem className="basis-[99%] pl-0 md:order-2 md:basis-1/2 md:pl-4">
                            <PreventiveCard
                                pet={pet}
                                price={platinumLevel?.price}
                                label={preventiveConfig.labels.advanced}
                                image={<PlatinumPreventive />}
                                benefits={platinumBenefits}
                                currentWellnessLevel={wellnessLevel}
                                wellnessLevel={"wellness-platinum"}
                                onChange={() => handleCardClick("wellness-platinum")}
                                sellingPoints={["More reimbursements", "More benefits"]}
                                showRecommendedAge={!isDefault}
                            />
                        </CarouselItem>
                    </>
                ) : (
                    <>
                        <CarouselItem className="basis-[99%] pl-0 md:order-2 md:basis-1/2 md:pl-4">
                            <PreventiveCard
                                pet={pet}
                                price={platinumLevel?.price}
                                label={preventiveConfig.labels.advanced}
                                image={<PlatinumPreventive />}
                                benefits={platinumBenefits}
                                currentWellnessLevel={wellnessLevel}
                                wellnessLevel={"wellness-platinum"}
                                onChange={() => handleCardClick("wellness-platinum")}
                                sellingPoints={["More reimbursements", "More benefits"]}
                                showRecommendedAge={!isDefault}
                            />
                        </CarouselItem>
                        <CarouselItem className="basis-[99%] pl-1 md:order-1 md:basis-1/2 md:pl-0">
                            <PreventiveCard
                                pet={pet}
                                price={goldLevel?.price}
                                label={preventiveConfig.labels.basic}
                                image={<GoldPreventive />}
                                benefits={goldBenefits}
                                currentWellnessLevel={wellnessLevel}
                                wellnessLevel={"wellness-gold"}
                                onChange={() => handleCardClick("wellness-gold")}
                                totalBenefitsValue={totalGoldBenefits}
                                showRecommendedAge={isDefault}
                                recommendPrevGoldCats={isDefault}
                            />
                        </CarouselItem>
                    </>
                )}
            </CarouselContent>
            <CarouselNavDots className="mt-4 md:hidden" />
        </Carousel>
    );
}
